import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.scss'
import SvgIcon from '@/icons'
import 'element-plus/dist/index.css'
import '@/router/permission'
import i18n from '@/i18n'
// import dataV from '@jiaminghi/data-view'
// 适配flex
// import '@/common/flexible.js'
// import axios from 'axios'

// // 按需引入vue-awesome图标
// import Icon from 'vue-awesome/components/Icon'
// import 'vue-awesome/icons/chart-bar.js'
// import 'vue-awesome/icons/chart-area.js'
// import 'vue-awesome/icons/chart-pie.js'
// import 'vue-awesome/icons/chart-line.js'
// import 'vue-awesome/icons/align-left.js'

// 引入echart
// import echarts from 'echarts'

const app = createApp(App)
SvgIcon(app)
app.use(store).use(router).use(i18n).mount('#app')

// axios.interceptors.request.use(config => {
//     console.log(config)
//     config.headers.Authorization = window.sessionStorage.getItem('token')
//     // 在最后必须return
//     return config
//   })
