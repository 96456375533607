import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { diffTokenTime } from '@/util/auth';
import store from '@/store';
import { ElMessage } from 'element-plus';
export default {
  __name: 'App',
  setup(__props) {
    window.addEventListener('click', () => {
      if (sessionStorage.getItem('authorization')) {
        if (diffTokenTime()) {
          ElMessage.error('登录超时');
          store.dispatch('app/logout');
          return Promise.reject(new Error('authorization失效了'));
        }
      }
    }, true);
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view);
    };
  }
};