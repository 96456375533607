import axios from 'axios'
import { ElMessage } from 'element-plus'
// import { diffTokenTime } from '@/util/auth'
// import store from '@/store'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000
})

// 请求拦截器，登录后添加请求头
service.interceptors.request.use(
  (config) => {
    // if (sessionStorage.getItem('authorization')) {
    //   if (diffTokenTime()) {
    //     store.dispatch('app/logout')
    //     return Promise.reject(new Error('authorization失效了'))
    //   }
    // }
    config.headers.Authorization = sessionStorage.getItem('authorization')
    return config
  },
  (error) => {
    return Promise.reject(new Error(error))
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    //  console.log(response)
     const data = response.data
    //  const headers = response.headers
     if (data.code === 20000) {
      return data
     } else {
      ElMessage.error(data.message)
      return Promise.reject(new Error(data.message))
     }
    },
  (error) => {
    error.response && ElMessage.error(error.response.data)
    return Promise.reject(new Error(error.response.data))
    }
)

export default service
