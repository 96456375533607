import request from './request'

// 封装为方法
export const login = (data) => {
    return request({
        url: '/api/public/v1/login/account',
        method: 'POST',
        data // 请求参数
    })
}
