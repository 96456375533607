import { login as loginApi } from '@/api/login'
import router from '@/router'
import { setTokenTime } from '@/util/auth'

export default {
  namespaced: true,
  state: {
    authorization: sessionStorage.getItem('authorization') || ''
  },
  mutations: {
    setAuthorization(state, authorization) {
      state.authorization = authorization
      sessionStorage.setItem('authorization', authorization)
    }
  },
  actions: {
    // token 来自登录页面 点击登录之后，所以不如直接放到actions中
    login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginApi(userInfo)
          .then((res) => {
            console.log(res)
            commit('setAuthorization', res.data)
            sessionStorage.setItem('username', userInfo.username)
            setTokenTime()
            router.replace('/')
            resolve()
            // return res
          })
          // .then((res) => {
          //   // 获取响应数据
          //   console.log(res)
          // })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 退出
    logout({ commit }) {
      commit('setAuthorization', '')
      sessionStorage.clear()
      router.replace('/login')
    }
  }
}
